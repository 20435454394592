/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Location } from '../models/Location';
import type { Player } from '../models/Player';
import type { PlayerTournament } from '../models/PlayerTournament';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PlayerService {
    /**
     * List players
     * @returns any OK
     * @throws ApiError
     */
    public static getPlayers({
        name,
        location,
        sort,
        order,
        limit,
        offset,
        minRating,
        maxRating,
        minDiff,
        maxDiff,
    }: {
        name?: Array<string>;
        location?: Array<string>;
        sort?: Array<'name' | 'rating' | 'last_update'>;
        order?: 'asc' | 'desc';
        limit?: number;
        offset?: number;
        minRating?: number;
        maxRating?: number;
        minDiff?: number;
        maxDiff?: number;
    }): CancelablePromise<{
        Players: Array<Player>;
        TotalCount: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/players',
            query: {
                name: name,
                location: location,
                sort: sort,
                order: order,
                limit: limit,
                offset: offset,
                min_rating: minRating,
                max_rating: maxRating,
                min_diff: minDiff,
                max_diff: maxDiff,
            },
            errors: {
                400: `Bad request. Parameters are invalid.`,
            },
        });
    }
    /**
     * Get player info
     * @returns Player OK
     * @throws ApiError
     */
    public static getPlayers1({ id }: { id: string }): CancelablePromise<Player> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/players/{id}',
            path: {
                id: id,
            },
            errors: {
                404: `No player with such id.`,
            },
        });
    }
    /**
     * Get player rating history
     * @returns any OK
     * @throws ApiError
     */
    public static getPlayersRating({ id, federation }: { id: string; federation?: string }): CancelablePromise<
        Array<{
            /**
             * Date when the rating was updated
             */
            Date: string;
            /**
             * Rating value
             */
            Rating: number;
            /**
             * Federation issued the rating
             */
            Federation: string;
            /**
             * Tournament ID
             */
            TID?: string;
        }>
    > {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/players/{id}/rating',
            path: {
                id: id,
            },
            query: {
                federation: federation,
            },
        });
    }
    /**
     * Get player tournament list
     * @returns PlayerTournament OK
     * @throws ApiError
     */
    public static getPlayersTournaments({ id }: { id: string }): CancelablePromise<Array<PlayerTournament>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/players/{id}/tournaments',
            path: {
                id: id,
            },
        });
    }
    /**
     * List player cities
     * @returns string OK
     * @throws ApiError
     */
    public static getPlayersCities(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/players/cities',
        });
    }
    /**
     * List player locations
     * @returns Location OK
     * @throws ApiError
     */
    public static getPlayersLocations(): CancelablePromise<Array<Location>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/players/locations',
        });
    }
}
