import { Tournament } from '@/shared/api';
import { useEffect, useMemo, useState } from 'react';

export type TTournamentsFilters = {
    year?: string[];
    title: string[];
    city?: string[];
    offset?: number;
    limit: number;
    order?: Array<'asc' | 'desc'>;
    status?: Tournament['Status'][];
    showIgnored?: boolean;
};

export type GroupedData = {
    yearMonth: string;
    events: Tournament[];
};

const getGroupedData = (data: Tournament[], isPast?: boolean) => {
    if (data) {
        const groupedData: GroupedData[] = data.reduce((acc: GroupedData[], item) => {
            const yearMonth = item.StartDate!.slice(0, 7); // Формат YYYY-MM
            const yearMonthEntry = acc.find((entry) => entry.yearMonth === yearMonth);

            if (yearMonthEntry) {
                yearMonthEntry.events.push({ ...item });
            } else {
                acc.push({
                    yearMonth: yearMonth,
                    events: [item],
                });
            }
            return acc;
        }, []);

        const order: Record<Tournament['Status'], number> = { active: 1, scheduled: 2, ended: 3 };

        groupedData.forEach((el) => el.events.sort((a, b) => order[a.Status] - order[b.Status]));

        groupedData.sort((a, b) =>
            isPast ? b.yearMonth.localeCompare(a.yearMonth) : a.yearMonth.localeCompare(b.yearMonth)
        );

        return groupedData;
    }
};

export const useTournamentList = () => {
    const [tournamentList, setTournamentList] = useState<Tournament[]>([]);
    const [tournamentsCount, setTournamentsCount] = useState<number>();
    const [previewTournaments, setPreviewTournaments] = useState<Tournament[]>([]);
    const [year, setYear] = useState<string[]>([]);
    const [title, setTitle] = useState<string[]>([]);
    const [city, setCity] = useState<string[]>();
    const [offset, setOffset] = useState<number>(0);
    const [isPast, setIsPast] = useState<boolean>(true);
    const [groupedTournament, setGroupedTournament] = useState<GroupedData[]>([]);
    const [isAdminMode, setIsAdminMode] = useState(false);
    const [pinId, setPinId] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [optionsCities, setOptionsCities] = useState<
        {
            value: string;
            label: string;
        }[]
    >();

    const allFilters: TTournamentsFilters = useMemo(
        () => ({
            year,
            title,
            city,
            offset,
            limit: 25,
            status: isPast ? ['active', 'ended'] : ['active', 'scheduled'],
            sort: ['status', 'start_date', 'num_players', 'num_registered'],
            order: isPast ? ['asc', 'desc', 'desc', 'desc'] : ['desc', 'asc', 'desc', 'desc'],
            showIgnored: isAdminMode,
        }),
        [year, title, city, offset, isPast, isAdminMode]
    );

    useEffect(() => setGroupedTournament(getGroupedData(tournamentList, isPast)!), [tournamentList, isPast]);

    return {
        tournamentList,
        allFilters,
        optionsCities,
        isLoading,
        tournamentsCount,
        previewTournaments,
        offset,
        groupedTournament: groupedTournament,
        title,
        isPast,
        isAdminMode,
        pinId,

        setTournamentList,
        setYear,
        setTitle,
        setCity,
        setOptionsCities,
        setIsLoading,
        setTournamentsCount,
        setPreviewTournaments,
        setOffset,
        setIsPast,
        setIsAdminMode,
        setPinId,
    };
};
