import { useMemo, useState } from 'react';
import { Player } from '@shared/api';

const MIN = -1000,
    MAX = 3000;

export const usePlayers = () => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [count, setCount] = useState(0);

    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [maxRating, setMaxRating] = useState<number>(MAX);
    const [maxDiff, setMaxDiff] = useState<number>();
    const [minRating, setMinRating] = useState<number>(MIN);
    const [minDiff, setMinDiff] = useState<number>();
    const [sort, setSort] = useState<('name' | 'rating' | 'last_update')[]>(['rating']);
    const [city, setCity] = useState<string[]>([]);
    const [name, setName] = useState<string[]>([]);
    const [cities, setCities] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);
    const allFilters = useMemo(
        () => ({
            offset,
            order,
            maxRating,
            maxDiff,
            minRating,
            minDiff,
            sort,
            name,
            location: city,
        }),
        [offset, order, maxRating, maxDiff, minRating, minDiff, sort, name, city]
    );
    return {
        players,
        isLoading,
        count,
        maxRating,
        minRating,
        cities,
        name,

        MIN,
        MAX,

        allFilters,

        setPlayers,
        setIsLoading,
        setCount,
        setOffset,
        setOrder,
        setMaxRating,
        setMaxDiff,
        setMinRating,
        setMinDiff,
        setSort,
        setCity,
        setName,
        setCities,
    };
};
